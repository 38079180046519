

export const sheet_options = [{
    label: "Calls",
    value: "Calls"
},
{
    label: "Vendors (For Zoho)",
    value: "Vendors",
},
{
    label: "Bills (For Zoho)",
    value: "Bills"
},
{
    label: "Vendors & Bills (For Zoho)",
    value: "Vendors_Bills"
}
]